<template>
  <div class="register">
    <AuthRegister />
  </div>
</template>

<script>
// @ is an alias to /src
import AuthRegister from "@/components/AuthRegister.vue";

export default {
  name: "Register",
  components: {
    AuthRegister,
  },
};
</script>
