<template>
  <div class="donaturlog">
    <DonaturLogTotal />
  </div>
</template>

<script>
// @ is an alias to /src
import DonaturLogTotal from "@/components/DonaturLogTotal.vue";

export default {
  name: "DonaturLog",
  components: {
    DonaturLogTotal,
  },
};
</script>
