<template>
  <v-main>
    <v-container fluid mt-n14>
      <v-layout align-center justify-center>
        <v-dialog v-model="dialogInfo1" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Untuk donasi buku silahkan menghubungi Operator terdekat di
              sekitar lokasi rumah anda, list Operator bisa dilihat di halaman
              utama.</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDialog1">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogInfo2" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Untuk donasi uang bisa dikirim ke no Rekening berikut:
              <br /><br />Bank BCA 11111111 <br />A/N. ASECA DIGITAL
              INDONESIA</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDialog2">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-flex xs12 sm8 md4>
          <v-row dense>
            <v-col cols="12">
              <v-card color="red darken-4 elevation-20" dark>
                <v-card-title class="text-h5 font-weight-bold">
                  Detail akun
                </v-card-title>
                <v-card-subtitle
                  >{{ user.username }} <br />{{ user.nama }} - {{ user.no_hp }}
                  <br />{{ user.alamat }}</v-card-subtitle
                >
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card color="red darken-1 elevation-20">
                <v-card-title class="text-h6 white--text font-weight-regular">
                  Daftar Buku / {{ total_items }} record
                </v-card-title>
                <v-data-table
                  :page="page"
                  :pageCount="last_page"
                  :headers="headers"
                  :items-per-page="5"
                  :items="items"
                  :options.sync="options"
                  :server-items-length="total_items"
                  :footer-props="footer_props"
                  :loading="loading"
                  class="elevation-1"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon medium color="red darken-2" @click="showItem(item)">
                      mdi-eye-circle
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="text-center mt-6">
            <v-col cols="12">
              <v-btn
                color="indigo darken-3"
                elevation="6"
                width="250px"
                dark
                x-large
                @click="openDialog1()"
              >
                <v-icon dark left> mdi-book-plus </v-icon> Donasikan Buku</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-btn
                color="green darken-3"
                elevation="6"
                width="250px"
                dark
                x-large
                @click="openDialog2()"
                ><v-icon dark left> mdi-currency-usd </v-icon> Donasikan
                Uang</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-btn
                color="primary"
                elevation="6"
                width="250px"
                x-large
                to="/donatur-log"
                ><v-icon dark left> mdi-file-document-edit </v-icon> Lihat
                Log</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-btn
                color="red darken-3"
                elevation="6"
                width="250px"
                outlined
                dark
                x-large
                @click="logout()"
                ><v-icon dark left> mdi-logout-variant </v-icon> Logout</v-btn
              >
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "DashboardUser",
  data() {
    return {
      page: 1,
      last_page: 0,
      dialogInfo1: false,
      dialogInfo2: false,
      loading: true,
      total_items: 0,
      footer_props: {
        "items-per-page-options": [5, 10],
        "items-per-page-text": "per page",
      },
      options: {},
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Judul Buku", value: "nama", align: "start", sortable: false },
        { text: "Tema", value: "tema", align: "start", sortable: false },
        { text: "Dibaca", value: "dibaca", align: "end", sortable: false },
        { text: "View", value: "actions", align: "end", sortable: false },
      ],
      items: [],
    };
  },
  watch: {
    options: {
      handler() {
        this.showDataLog();
      },
      deep: true,
    },
    dialogInfo1(val) {
      val || this.closeDialog1();
    },
    dialogInfo2(val) {
      val || this.closeDialog2();
    },
  },
  methods: {
    openDialog1() {
      this.dialogInfo1 = true;
    },
    openDialog2() {
      this.dialogInfo2 = true;
    },
    closeDialog1() {
      this.dialogInfo1 = false;
    },
    closeDialog2() {
      this.dialogInfo2 = false;
    },
    logout() {
      this.$auth.destroyToken();
      this.$router.push("/");
    },
    showItem(item) {
      this.$router.push({ path: "/book-donatur-detail/" + item.id });
    },
    showDataLog() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      var pageNumber = page;

      this.$http
        .get("/book_list_by_user/" + pageNumber + "/" + itemsPerPage)
        .then(function (res) {
          this.loading = false;
          this.items = [];
          this.last_page = 1;
          if (res.body.status === "success") {
            this.items = res.body.data;
            this.total_items = res.body.total_items;
            this.last_page = res.body.last_page;
          }
        })
        .catch(function (e) {
          this.$vtoast.show({
            message: e,
            icon: "mdi-alert-circle",
            color: "red darken-4",
          });
        });
    },
  },
  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },
  filters: {
    rupiah: function (value) {
      var val = value ? value.toString() : "0";
      return val.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.");
    },
  },
};
</script>
