<template>
  <div class="log">
    <HistoryLog />
  </div>
</template>

<script>
// @ is an alias to /src
import HistoryLog from "@/components/HistoryLog.vue";

export default {
  name: "Log",
  components: {
    HistoryLog,
  },
};
</script>
