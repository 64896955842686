import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import Dashboard from "../views/Dashboard.vue";
import Log from "../views/Log.vue";
import Book from "../views/Book.vue";
import BookDetail from "../views/BookDetail.vue";
import BookDonaturDetail from "../views/BookDonaturDetail.vue";
import DonaturLog from "../views/DonaturLog.vue";
import Donatur from "../views/Donatur.vue";
import Operator from "../views/Operator.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/log",
    name: "Log",
    component: Log,
  },
  {
    path: "/book",
    name: "Book",
    component: Book,
  },
  {
    path: "/book-detail/:id",
    name: "BookDetail",
    component: BookDetail,
  },
  {
    path: "/book-donatur-detail/:id",
    name: "BookDonaturDetail",
    component: BookDonaturDetail,
  },
  {
    path: "/donatur-log",
    name: "DonaturLog",
    component: DonaturLog,
  },
  {
    path: "/donatur-list",
    name: "Donatur",
    component: Donatur,
  },
  {
    path: "/operator-list",
    name: "Operator",
    component: Operator,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const noauth_pages = [
    "Login",
    "Register",
    "Home",
    "Log",
    "Book",
    "BookDetail",
    "Donatur",
    "Operator",
  ];
  var token = localStorage.getItem("authTokenAseca");

  if (noauth_pages.includes(to.name) === false && !token)
    next({ name: "Login" });
  if (noauth_pages.includes(to.name) === true && token)
    next({ name: "Dashboard" });
  else next();
});

export default router;
