import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUser: {},
  },
  plugins: [
    createPersistedState({
      key: "vuexaseca",
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  mutations: {
    setCurrentUser: function (state, user) {
      state.currentUser = user;
    },
    clearCurrentUser: function (state) {
      state.currentUser = {};
    },
  },
  actions: {},
  modules: {},
});
