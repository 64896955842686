import { render, staticRenderFns } from "./DonaturLogTotal.vue?vue&type=template&id=37b8e704&"
import script from "./DonaturLogTotal.vue?vue&type=script&lang=js&"
export * from "./DonaturLogTotal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports