<template>
  <div class="donatur">
    <DonaturList />
  </div>
</template>

<script>
// @ is an alias to /src
import DonaturList from "@/components/DonaturList.vue";

export default {
  name: "Donatur",
  components: {
    DonaturList,
  },
};
</script>
