<template>
  <div class="bookdonaturdetail">
    <BookDonaturDetailLog />
  </div>
</template>

<script>
// @ is an alias to /src
import BookDonaturDetailLog from "@/components/BookDonaturDetailLog.vue";

export default {
  name: "BookDonaturDetail",
  components: {
    BookDonaturDetailLog,
  },
};
</script>
