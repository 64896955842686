<template>
  <v-container>
    <v-row class="text-center mt-6">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.png')"
          class="my-3"
          contain
          height="180"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">ASECA DIGITAL</h1>

        <p class="subheading font-weight-regular">
          Selamat datang di Apps ASECA - Tingkatkan Literasi Anak Indonesia
        </p>
      </v-col>
      <v-col cols="12">
        <v-btn color="primary" elevation="6" width="250px" x-large to="/login"
          ><v-icon dark left> mdi-login-variant </v-icon> Masuk</v-btn
        >
      </v-col>
      <v-col cols="12">
        <v-btn
          outlined
          color="indigo darken-3"
          elevation="6"
          width="250px"
          dark
          x-large
          to="/register"
        >
          <v-icon dark left> mdi-account-plus </v-icon> Daftar Donatur
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-btn
          color="green darken-3"
          elevation="6"
          width="250px"
          dark
          x-large
          to="/donatur-list"
        >
          <v-icon dark left> mdi-table-network </v-icon> List Donatur
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-btn
          color="teal darken-3"
          elevation="6"
          width="250px"
          dark
          x-large
          to="/operator-list"
        >
          <v-icon dark left> mdi-office-building-marker</v-icon> Lokasi Operator
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-btn color="orange" elevation="6" width="250px" x-large to="/log">
          <v-icon dark left> mdi-chart-bar </v-icon> Lihat Log</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Welcome",
};
</script>
