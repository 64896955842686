<template>
  <v-main>
    <v-container fluid mt-n14>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-row dense>
            <v-col cols="12">
              <v-card color="red darken-4 elevation-20" dark>
                <div class="d-flex flex-no-wrap justify-space-between">
                  <div>
                    <v-card-title class="text-h5 font-weight-bold"
                      >Summary</v-card-title
                    >
                    <v-card-subtitle class="text-h6">
                      TOTAL OPERATOR: {{ totaloperator }}<br />TOTAL DONATUR:
                      {{ totaldonatur }}<br />TOTAL BUKU: {{ totalbuku }}
                      <br />TOTAL UANG: {{ totaluang | rupiah }}
                    </v-card-subtitle>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card color="white elevation-20">
                <div class="d-flex flex-no-wrap justify-space-between">
                  <div>
                    <v-card-title class="text-h5 font-weight-bold"
                      >Bulan Ini</v-card-title
                    >
                    <v-card-subtitle class="text-h6"
                      >Buku Ke ASECA: {{ totalbuku_bln_sekarang }} <br />
                      Buku Dibaca: {{ totalbuku_dibaca_bln_sekarang }} <br />
                      Total Buku Ke ASECA: 0% <br />
                      Total Buku Dibaca: 0% <br />
                      Total Buku Rusak: 0%</v-card-subtitle
                    >
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="text-center mt-6">
            <v-col cols="12">
              <v-btn
                color="blue darken-3"
                elevation="6"
                width="250px"
                dark
                x-large
                to="/book"
                ><v-icon dark left> mdi-book </v-icon> Daftar Buku</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-btn
                color="green darken-4"
                elevation="6"
                width="250px"
                dark
                x-large
                target="_blank"
                href="https://api.whatsapp.com/send?text=untuk melihat data log ASECA, silahkan klik link berikut%0ahttps://www.aseca.id/log"
                ><v-icon dark left> mdi-whatsapp </v-icon> Share WA</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-btn
                color="grey darken-4"
                outlined
                elevation="6"
                width="250px"
                dark
                x-large
                to="/"
                ><v-icon dark left> mdi-arrow-left-circle </v-icon> Back</v-btn
              >
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "HistoryLog",
  data() {
    return {
      totaldonatur: 0,
      totaloperator: 0,
      totalbuku: 0,
      totaluang: 0,
      totalbuku_bln_sekarang: 0,
      totalbuku_dibaca_bln_sekarang: 0,
      loading: true,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$http
        .get("/book_count_noauth")
        .then(function (res) {
          if (res.body.status === "success") {
            this.totaldonatur = res.body.total.total_donatur;
            this.totaloperator = res.body.total.total_operator;
            this.totalbuku = res.body.total.total_buku;
            this.totaluang = res.body.total.total_rupiah;
            this.totalbuku_bln_sekarang =
              res.body.total.total_buku_bln_sekarang;
            this.totalbuku_dibaca_bln_sekarang =
              res.body.total.total_buku_dibaca_bln_sekarang;
          }
        })
        .catch(function (e) {
          this.$vtoast.show({
            message: e,
            icon: "mdi-alert-circle",
            color: "red darken-4",
          });
        });
    },
  },
  filters: {
    rupiah: function (value) {
      var val = value ? value.toString() : "0";
      return val.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
  },
};
</script>
