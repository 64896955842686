<template>
  <v-main>
    <v-container fluid mt-n14>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md8>
          <v-row dense>
            <v-card
              :loading="loading"
              class="mx-auto my-3"
              width="374"
              max-width="374"
            >
              <template slot="progress">
                <v-progress-linear
                  color="deep-red"
                  height="10"
                  indeterminate
                ></v-progress-linear>
              </template>

              <v-img height="390" :src="foto"></v-img>

              <v-card-title>{{ judul }}</v-card-title>

              <v-card-text>
                <v-row align="center" class="mx-0">
                  <div class="grey--text text-subtitle-1">{{ tema }}</div>
                </v-row>

                <div class="my-4">{{ penerbit }} - {{ tahun }}</div>

                <div>
                  {{ sinopsis }}
                </div>
              </v-card-text>

              <v-divider class="mx-4"></v-divider>

              <v-card-title>{{ donatur }}</v-card-title>

              <v-card-text>
                <div>
                  dibaca: {{ dibaca }}x <br />
                  lokasi sekarang: {{ operator_id }} <br />
                  masuk ASECA: {{ masuk_aseca }}
                </div>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="green darken-4"
                  dark
                  target="_blank"
                  :href="cur_url"
                  text
                >
                  <v-icon dark left> mdi-whatsapp </v-icon> Share WA
                </v-btn>
                <v-btn color="grey darken-4" @click="$router.back()" dark text>
                  Back
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "BookDetailLog",
  data() {
    return {
      loading: true,
      foto: require("../assets/no-image.png"),
      judul: "",
      sinopsis: "",
      penerbit: "",
      dibaca: "0",
      tahun: "",
      masuk_aseca: "",
      operator_id: "",
      tema: "",
      donatur: "",
      id: this.$route.params.id,
      cur_url:
        "https://api.whatsapp.com/send?text=untuk melihat data detail buku, silahkan klik link berikut%0ahttps://www.aseca.id" +
        this.$route.fullPath,
    };
  },
  mounted() {
    this.showBook();
  },
  methods: {
    showBook() {
      this.loading = true;
      this.$http
        .get("/book_detail_by_id/" + this.id)
        .then(function (res) {
          this.loading = false;
          if (res.body.status === "success") {
            this.foto = res.body.data.foto;
            this.judul = res.body.data.nama;
            this.penerbit = res.body.data.penerbit;
            this.masuk_aseca = res.body.data.masuk_aseca;
            this.tahun = res.body.data.tahun;
            this.tema = res.body.data.tema;
            this.dibaca = res.body.data.dibaca;
            this.operator_id = res.body.data.operator_id;
            this.donatur = res.body.data.donatur;
            this.sinopsis = res.body.data.sinopsis;
          } else {
            this.$router.push({ path: "/book" });
          }
        })
        .catch(function (e) {
          this.$vtoast.show({
            message: e,
            icon: "mdi-alert-circle",
            color: "red darken-4",
          });
        });
    },
  },
};
</script>
