<template>
  <div class="operator">
    <OperatorList />
  </div>
</template>

<script>
// @ is an alias to /src
import OperatorList from "@/components/OperatorList.vue";

export default {
  name: "Operator",
  components: {
    OperatorList,
  },
};
</script>
